/* Custom Font Setup */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

/*  */
:root {
  --base: #fefefe;
  --primary: #333;
  --gray: #a3a3a3;
  --bg-white: #fcfcfc;
  --bg-gray: #f2f2f2;
  --bg-sky: #f0f9ff;
  --bg-light-green: #f0fdf4;
  --bg-dark-green: #16a34a;
  --green: #22c55e;
  --red: #ef4444;
  --text-gray: #1f2937;
  --text-ligth-gray: #4b5563;
  --border-gray: #ebebeb;
  --border-sky: #0ea5e9;
  --border-gray-dark: #ddd;
  --btn-black: #111827;
  --btn-gray: #374151;

}

/* Reset some default browser styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 16px;
  line-height: 1.5;
}

body {
  font-family: "Noto Sans", sans-serif;
  background-color: var(--bg-white);
  color: var(--primary);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


/* Typography */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0.25rem 0;
}

p {
  margin: 0.25rem 0;
}

input {
  display: inline-block;
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--gray);
}

button {
  display: inline-block;
  margin: 8px 0;
  border-radius: 8px;
  border: 1px solid var(--gray);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
}

/* Responsive Grid System */
.container-fluid {
  width: 100%;
  margin: 0 auto;
  padding: 1rem;
  max-width: 1280px;
}

.container {
  width: 91.666667%;
  margin: 0 auto;
  padding: 1rem;
  max-width: 1280px;
}

/* Login Page */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.login {
  width: 60%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border: 1px solid var(--border-gray);
  border-radius: 20px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.login__image {
  width: 373px;
}

.login__image-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: right;
  border-radius: 20px 0 0 20px;
}

.login__content {
  width: 373px;
  padding: 50px 40px;
}

.login__title,
.scanner__title {
  font-weight: 600;
  color: var(--text-gray);
}

.login__subtitle {
  color: var(--text-ligth-gray);
  font-size: 14px;
}

.login__form {
  margin: 12px 0;
}

.login__input-section {
  margin: 20px 0;
  position: relative;
}

.password-toggle-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  color: var(--btn-gray);
}

.login__input {
  padding: 12px;
}

.login__btn {
  display: block;
  height: 36px;
  width: 100px;
  background: var(--btn-black);
  color: var(--base);
  cursor: pointer;
}

.login__btn:hover {
  background: var(--btn-gray);
  color: var(--base);
}

.login__info {
  margin: 20px 0 0;
}

.login__info-text {
  font-size: 12px;
  color: var(--text-ligth-gray);
}

.login__error {
  color: var(--red);
  font-size: 12px;
}

/* Loader */
.full-page-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-content {
  text-align: center;
}

/* Scanner */
.scanner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 100vh;
}

.scanner__desc {
  font-size: 14px;
  color: var(--text-ligth-gray);
}

.scanner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.scan-button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
  cursor: pointer;
  background: var(--btn-black);
  color: var(--base);
}

.scan-button:hover {
  background: var(--btn-gray);
  color: var(--base);
}

.video-scanner {
  width: 600px;
  height: 250px;
  max-width: 1000px;
  max-height: 600px;
  display: block;
}

.hidden {
  display: block;
}

.error {
  color: var(--red);
  margin-top: 10px;
}

/* Form */
.form-container {
  height: 100vh;
}

.form-header {
  margin: 25px;
}
.form-input {
  width: 100%;
  padding: 12px 16px;
  margin-bottom: 1rem ;
  border: 1px solid var(--border-gray-dark);
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
  outline: none;
  transition: border-color 0.3s ease;
}

.form-input:focus {
  border-color: var(--border-sky);
}

.form-details {
  line-height: 2;
  margin: 10px 0;
  background: var(--bg-gray);
  padding: 10px 20px;
  border-radius: 8px;
}

/* Remark Form */
.remark-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--bg-white);
  border-radius: 12px;
  padding: 12px;
}

.form-title {
  font-weight: 600;
  text-align: center;
}

.remark-form {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  gap: 10px;
  width: 100%;
  max-width: 600px;
  padding: 20px;
  border-radius: 8px;
}

.form-label {
  grid-row: 1;
  font-size: 16px;
  font-weight: 600;
  color: var(--text-gray);
}

.form-textarea {
  grid-row: 2;
  width: 100%;
  height: 150px;
  padding: 10px;
  font-size: 16px;
  border: 2px solid var(--text-ligth-gray);
  border-radius: 8px;
  outline: noen;
  resize: none;
}

.info-text {
  grid-row: 3;
  text-align: right;
}

.form-btn {
  width: 100%;
  grid-row: 4;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  background: var(--btn-black);
  color: var(--base);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease;
}

.form-btn:hover {
  background: var(--btn-gray);
  color: var(--base);
}

.form-btn:disabled {
  background: var(--bg-gray);
  color: var(--primary);
  cursor: not-allowed;
}

/* Navbar */
.navbar-container {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 9999;
}


.logo-image {
  height: 40px;
  width: auto;
}

.nav-links {
  display: flex;
  gap: 20px;
}

.logo-image {
  width: 80px;
  height: 60px;
}

.logout-btn {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  background: var(--btn-black);
  color: var(--base);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  transition: background-color 0.3s ease;
}

.logout-btn:hover {
  background: var(--btn-gray);
  color: var(--base);
}

/* Admin navbar */
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 4rem;
  background-color: var(--base);
  color: var(--text-gray);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 9999;
}

.nav-link,
.dropbtn {
  color: var(--text-gray);
  text-decoration: none;
  cursor: pointer;
  background-color: none;
  border: none;
  font-weight: normal;

}

.nav-links {
  display: flex;
  align-items: center;
  gap: 4rem;
}

.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: var(--base);
  min-width: 180px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content .dropdown-link {
  color: var(--text-gray);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content.show {
  display: block;
}

.hamburger {
  display: none;
  cursor: pointer;
}

.hamburger svg {
  fill: var(--text-gray);
}

/* Home - Table */
.admin-container {
  width: 91.666667%;
  margin: 0 auto;
  padding: 1rem;
  max-width: 1280px;
}

.admin-profile {
  margin: 50px auto;
  background: var(--bg-sky);
  padding: 50px 75px;
  border-radius: 20px;
}

.admin-heading {
  text-align: center;
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 30px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--border-sky)
}

.admin-row {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 30px;
  margin-bottom: 1rem;
}

.admin-row h5 {
  flex-basis: 200px;
  margin: 0;
  font-size: 16px;
  font-weight: 500;
}

.admin-row p {
  flex: 1;
  margin: 0;
  font-size: 16px;
}

.user-container {
  width: 91.666667%;
  margin: 0 auto;
  padding: 1rem;
  max-width: 1350px;
}

.user-heading {
  text-align: center;
}

/* Table */

.table-container {
  overflow-x: auto;
}

.responsive-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 16px;
  text-align: left;
  white-space: nowrap;
}

.responsive-table th,
.responsive-table td {
  padding: 12px 15px;
  border: 1px solid var(--border-gray-dark);
  min-width: 100px;
}

.responsive-table th {
  background-color: var(--bg-sky);
}

.responsive-table .btn {
  padding: 5px 10px;
  margin: 0 5px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.update-btn {
  background-color: var(--green);
  color: var(--base);
}

.delete-btn {
  background-color: var(--red);
  color: var(--base);
}

/* Form */
/* Form container styling */
.form-container {
  margin: 20px auto;
  padding: 2rem;
  border-radius: 10px;
  max-width: 400px;
  width: 100%;
}

h2 {
  text-align: center;
  margin-bottom: 2rem;
}

.registration-form {
  display: flex;
  flex-direction: column;
}

/* Floating label styling */
.registration-form {
  display: flex;
  flex-direction: column;
}

.input-group {
  margin-bottom: 1rem;
}

.input-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: var(--text-ligth-gray);
}

.input-group input {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  color: var(--text-gray);
  border: 1px solid var(--border-gray-dark);
  border-radius: 5px;
}

.input-group input::placeholder {
  color: var(--gray);
}

.error-message {
  color: var(--red);
}

/* Submit button styling */
.submit-btn {
  padding: 1rem;
  font-size: 1rem;
  color: var(--base);
  background-color: var(--btn-black);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: var(--btn-gray);
}

/* Search bar */
.search-container {
  display: flex;
  align-items: center;
  background-color: var(--bg-white);
  border: 2px solid var(--border-gray);
  border-radius: 8px;
  padding: 0.5rem;
  width: 100%;
  max-width: 400px;
  margin: 1rem auto;
  cursor: pointer;
}

.search-icon {
  width: 32px;
  height: 32px;
  margin-right: 8px;
  color: var(--btn-black);
}

.search-input {
  border: none;
  background: transparent;
  width: 100%;
  font-size: 16px;
  padding: 0.5rem;
  outline: none;
}

.search-input::placeholder {
  color: #888;
}

/* Date Filter */
.date-filter-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.date-input-container {
  flex: 1;
  min-width: 150px;
}

.date-input {
  display: block;
  width: 200px;
  margin: 5px 0 20px;
  margin-left: auto;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  background-color: transparent;
}


/* Filter */
.filter-container {
  display: flex;
  align-items: center;
  background-color: var(--bg-white);
  border: 2px solid var(--border-gray);
  border-radius: 8px;
  padding: 0.5rem;
  width: 100%;
  max-width: 150px;
  margin: 1rem auto;
  cursor: pointer;
}

.filter-icon {
  width: 32px;
  height: 32px;
  margin-right: 8px;
  color: var(--btn-black);
}

.filter-select {
  border: none;
  background: var(--bg-white);
  font-size: 16px;
  outline: none;
  color: var(--text-gray);
  padding: 0.5rem;
  width: 100%;
}

/* Filter Block */
.filter-block {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 10px;
  border-radius: 8px;
  flex-wrap: wrap;
}

.search,
.filter {
  flex: 1;
  min-width: 200px;
  margin: 0;
}

.filter {
  flex: 0 1 auto;
}

/* Edit Form */
.edit-form {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid var(--border-gray);
  border-radius: 16px;
}

.edit-form h3 {
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}

.edit-form form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  margin-bottom: 5px;
  font-weight: 500;
}

.form-group input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-actions {
  grid-column: 1 / -1;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.form-actions button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form-actions button:first-child {
  background-color: transparent;
  border: 1px solid var(--btn-black);
  color: var(--text-gray);
}

.form-actions button:last-child {
  background-color: var(--btn-black);
  color: white;
}

.form-actions button:first-child:hover {
  border: 1px solid var(--red);
  color: var(--red);
}

.form-actions button:last-child:hover {
  background-color: var(--btn-gray);
}

/* SelectRole.css */
.select-role-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
  text-align: center;
}

.title {
  font-size: 2rem;
  color: var(--text-gray);
  font-weight: 400;
}

.subtitle {
  font-size: 1rem;
  color: var(--text-light-gray);
  margin-bottom: 1.5rem;
}

.button-container {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  padding: 20px;
  font-size: 1rem;
  font-weight: 300;
  color: var(--text-gray);
  background-color: transparent;
  border: 2px solid var(--border-gray-dark);
  border-radius: 8px;
  cursor: pointer;
  transition: color 0.3s ease, border 0.3s ease, background 0.3s ease;
}

.button svg {
  margin-bottom: 10px;
}

.button:hover {
  border: 2px solid var(--border-gray-dark);
  background: var(--btn-gray);
  color: var(--base);
}

/* Record Card */
.record-container {
  padding: 20px;
}

.record-title {
  text-align: center;
  margin-bottom: 20px;
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.record-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 300px;
  padding: 15px;
  transition: box-shadow 0.3s ease;
}

.card-body p {
  margin: 5px 0;
}

.download-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 10px;
  background: var(--bg-light-green);
  font-size: 14px;
  font-weight: 600;
  margin-left: auto;
  cursor: pointer;
}

.download-btn:hover {
  background: var(--bg-dark-green);
  color: var(--base);
}

/* Questions */
.questions {
  margin: 2.5px 0;
}

.question__title {
  color: var(--text-gray);
  font-weight: 500;
}

.question__options {
  display: flex;
  gap: 100px;
  width: 100%;
  margin-bottom: 1rem;
}
.question__label{
  display: flex;
  gap: 8px;
}
.question__dropdown {
  width: 100%;
  border-radius: 6px;
  font-size: 16px;
}


.record-title {
  font-weight: 500;
  color: var(--primary);
}

/* Theme Toggle */
.theme-toggle {
  background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    transition: all 0.3s ease;
}
.light-toggle {
  fill: currentColor;
  width: 32px;
  height: 32px;
}
.dark-toggle {
  fill: currentColor;
  width: 32px;
  height: 32px;
  color: #fff;
}

.theme-toggle:hover {
  transition: all 0.3s ease;
}


/* Media Queries for Responsive Design */
@media (max-width: 768px) {

  /* Login Page */
  .container-fluid {
    margin: 0;
    padding: 0;
  }

  .login-container {
    display: block;
    width: 100%;
    height: 100vh;
  }

  .login {
    width: 100%;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    border-radius: 0;
    box-shadow: none;
    border: none;
  }

  .login__image {
    width: 100%;
    height: auto;
  }

  .login__image-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0;
  }

  .login__content {
    width: 100%;
    height: auto;
    padding: 30px;
    box-shadow: 0px -30px 60px 45px rgb(255, 255, 255);
  }

  .login__icon,
  .scanner__icon {
    display: block;
    width: 80px;
    height: 60px;
    margin: 0 auto;
  }

  .scanner-container {
    gap: 10px;
  }

  .login__title {
    text-align: center;
  }

  .scanner__title {
    text-align: center;
    font-size: 18px;
  }

  .scanner__desc {
    font-size: 13px;
  }

  .scan-button {
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
    border-radius: 4px;
  }

  .login__subtitle {
    display: block;
    text-align: center;
  }

  .login__btn {
    display: block;
    margin: 0 auto;
  }

  .login__info {
    text-align: center;
  }

  /* User Navbar */
  .navbar-container {
    padding: 0.5rem;
  }

  .navbar {
    padding: 0.5rem 1rem;
    z-index: 2;
  }

  .logo-image {
    height: 40px;
    width: 60px;
  }

  .logout-btn {
    font-size: 12px;
    padding: 0.5rem;
  }

  /* Admin Navbar */
  .navbar {
    padding: 1rem;
    z-index: 2;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    padding: 25px;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: var(--base);
    width: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .nav-links {
    gap: 1rem;
  }

  .nav-links.open {
    display: flex;
  }

  .dropdown-content {
    position: static;
    box-shadow: none;
  }

  .hamburger {
    display: block;
  }

  /* Admin Home page */

  .admin-container {
    width: 100%;
    margin: 0 auto;
  }

  .admin-row h5 {
    flex-basis: 105px;
  }

  .user-container {
    width: 100%;
    margin: 0 auto;
  }

  .table-container {
    overflow-x: auto;
  }

  .responsive-table tr {
    margin-bottom: 15px;
  }

  /* Form  */

  .form-container {
    padding: 1.5rem;
  }

  .input-group input {
    padding: 0.75rem 0.5rem;
    font-size: 0.875rem;
  }

  .form-btn__container {
    width: 100%;
  }

  .submit-btn {
    padding: 0.75rem;
    font-size: 0.875rem;
  }

  /* Questions */
  .question__dropdown {
    font-size: 12px;
    padding: 8px 12px;
  }

  /* Search and Filter */

  .search-container input,
  .filter-container select {
    max-width: 100%;
    font-size: 0.9rem;
  }

  .date-input {
    font-size: 14px;
  }

  .date-input-container {
    min-width: 100px;
  }

  .edit-form form {
    grid-template-columns: 1fr;
  }

  .form-actions {
    flex-direction: column;
    gap: 10px;
  }

  .filter-block {
    flex-direction: column;
    align-items: stretch;
  }

  .search,
  .filter {
    min-width: 100%;
  }

  /* Record Card */
  .cards-container {
    flex-direction: column;
    align-items: center;
  }

  /* Select Type */
  .title {
    font-size: 1.5rem;
  }

  .subtitle {
    font-size: 0.875rem;
  }

  .button {
    width: 100%;
    height: auto;
    padding: 15px;
    font-size: 1.2rem;
  }

  .button-container {
    flex-direction: column;
    gap: 10px;
  }

}

@media (min-width: 769px) and (max-width: 1234px) {
  .login {
    width: 95%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    border: 1px solid var(--border-gray);
    border-radius: 20px;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  }

  .logo-image {
    height: 50px;
    width: 70px;
  }
}